<template>
  Error - {{ category }}
</template>

<script>
  export default {
    props: [
      'layout',
      'category',
    ]
  }
</script>